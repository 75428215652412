import { compareStrings } from './compareStrings'

export function compareTreeEntryPath (a, b) {
  // Git sorts tree entries as if there is a trailing slash on directory names.
  return compareStrings(appendSlashIfDir(a), appendSlashIfDir(b))
}

function appendSlashIfDir (entry) {
  return entry.mode === '040000' ? entry.path + '/' : entry.path
}
